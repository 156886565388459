$(function(){
  var cDumHovEnable = true;
  var cHovMaster = 'data-js-hov_master_slick02';

  var cMaster = 'data-js-slick02';
  var vObj = $('[' + cMaster + ']');
  //
  var cPrev = 'data-js-slick02__prev';
  var cNext = 'data-js-slick02__next';
  var cSwipeSpeed = 300;
  $(vObj).slick({
    autoplay:false,
    autoplaySpeed : 100,
    speed: cSwipeSpeed,
    cssEase: 'ease-in-out',//seCSS: false,でないと機能しない
    // useCSS: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<div class="c_slick02__arw c_slick02__arw_prev"' + cPrev + '></div>',
    nextArrow: '<div class="c_slick02__arw c_slick02__arw_next" ' + cNext + '></div>',
    // rtl: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          speed: 500,
          slidesToShow: 1,
        }
      },
    ]
  });

  var vPrev = $('[' + cPrev + ']');
  var vNext = $('[' + cNext + ']');

  //////////
  var cDummy = 'data-js-slick02__dummy';

  var cLeft = 'data-js-slick02__prev';
  var cRight = 'data-js-slick02__next';
  ///////////////////

  var vLeft = $('[' + cLeft + ']');
  var vRight = $('[' + cRight + ']');

  $(vLeft).click(function() {
    fPaging('left');
  })
  $(vRight).click(function() {
    fPaging('right');
  })

  function fPaging(vDirection){
    if(cDumHovEnable){
      switch (vDirection) {
        case 'left':
        $(vLeft).closest('[' + cMaster + ']').find('[' + cPrev + ']').trigger("click");
        break;
        case 'right':
        $(vRight).closest('[' + cMaster + ']').find('[' + cNext + ']').trigger("click");
        break;
        default:
      }
    }

    fDummyHide();
  }

  function fDummyHide(){
    // $(vLeft).css('display', 'none');
    // $(vRight).css('display', 'none');
    // $(vLeft).css('pointer-events', 'none');
    // $(vRight).css('pointer-events', 'none');
  }
  function fDummyShow(){
    // $(vLeft).css('display', 'block');
    // $(vRight).css('display', 'block');
    // $(vLeft).css('pointer-events', 'all');
    // $(vRight).css('pointer-events', 'all');
  }
});
