(function($) {
  $(window).on('load', function(){
    ///////////////////////////////////
    var cMenu = 'data-js-header-fullmenu-menu';
    var vMenu = $('[' + cMenu + ']');
    var cOpen = 'data-js-header-fullmenu-open';
    var vOpen = $('[' + cOpen + ']');
    var cClose = 'data-js-header-fullmenu-close';
    var vClose = $('[' + cClose + ']');
    $(vOpen).click(function() {
      // $(vMenu).slideDown();
      $(vMenu).fadeIn();
      $('html').css('overflow', 'hidden');
    })
    $(vClose).click(function() {
      // $(vMenu).slideUp();
      $(vMenu).fadeOut();
      $('html').css('overflow', 'initial');
    })
    ///////////////////////////////////
    // スクロールによるロゴの色変化
    var cLogo01;
    var vLogo01;
    ////////
    var cBackRef;
    var vBackRef;
    var cTxtRef;
    var vTxtRef;

    fInit();
    $(window).scroll(function() {
      fInit();
    });
    function fGlobalInit(){
      cLogo01 = 'data-js-logo01';
      vLogo01 = $('[' + cLogo01 + ']');
      ////////
      cBackRef = 'data-js-luminance-back';
      vBackRef = $('[' + cBackRef + ']');
      cTxtRef = 'data-js-luminance-txt';
      vTxtRef = $('[' + cTxtRef + ']');
    }

    function fInit() {
      fGlobalInit();
      var vIndex = fGetCurrentRefIndex();

      var vBGClr = backgroundColor($(vBackRef).get(vIndex));
      // console.log(vBGClr)
      var vLuminance = luminance(vBGClr);
      var cThreshold = 120;
      // console.log(vBGClr)
      // console.log(vLuminance)
      /////
      fTopPageLogoVisible(vIndex);
      ////

      var vClr;
      var cClrClass = 'is-clr';

      if(isNaN(vLuminance)){
        vClr = 'black';
        vClrClass = cClrClass;
      }else{
        if(vLuminance > cThreshold){
          vClr = 'black';
          vClrClass = cClrClass;
        }else{
          vClr = 'white';
          vClrClass = '';
        }
      }


      $(vLogo01).find('path').attr('fill', vClr);

      $(vTxtRef).removeClass(cClrClass).addClass(vClrClass);
    }
    ///////
    function fTopPageLogoVisible(vIndex) {
      var cScrollClass = 'is-scrolled';
      if(vIndex == 0){
        $(vLogo01).find('svg').removeClass(cScrollClass);
      }else{
        $(vLogo01).find('svg').addClass(cScrollClass);
      }

    }
    ///////
    function fGetCurrentRefIndex(){
      var vRet = 0;
      var vScroll = $(window).scrollTop();
      var vTops = [];
      $(vBackRef).each(function(i, elem) {
        var vTop;
        if($(elem).attr(cBackRef) == '0'){
          vTop = 0;
        }else{
          vTop = $(elem).offset().top;
        }
        if(i == $(vBackRef).length - 1){
          // フッタ（フッタ競り上がり表示のための対応）
          vTop = $('main').height();
        }
        vTops.push(Math.floor(vTop));
      });

      $(vTops).each(function(i, elem) {
        var vLength = $(vTops).length - 1;
        if(i < vLength){
          if((vTops[i] < vScroll) && (vScroll < vTops[i + 1])){
            vRet = i;
            // console.log('a')
            return false;
          }else{
            if(vTops[vLength] <= vScroll){
              // フッタ部分までスクロールした状態
              vRet = vLength
              // console.log('b')
              return false;
            }else{
              // console.log('c')
            }
          }
        }
      });
      // console.log(vTops)
      // console.log(vScroll)
      // console.log(vRet)
      return vRet;
    }
    function backgroundColor(el) {
      // console.log($(el)[0])
      // console.log($(el)[0].attr(cBackRef))
      var color = window.getComputedStyle(el)["background-color"];
      // console.log(color)
      var rgba = color
        .replace(/^rgba?\(/, "")
        .replace(/\)$/, "")
        .split(", ")
        .map(function(val) { return parseInt(val); });
    // console.log(color)
      if (rgba.length > 3 && rgba[rgba.length - 1] === 0) {
        return backgroundColor(el.parentElement);
      } else {
        return rgba;
      }
    }
    function luminance(rgba) {
      var r = 0.298912;
      var g = 0.586611;
      var b = 0.114478;
      return Math.floor(r * rgba[0] + g * rgba[1] + b * rgba[2]);
    }
    ///////
    ///////////////////////////////////
    // ヘッダ高さ確保
    var cHeaderAttr = 'data-js_header';
    var vFixedElem = $('[' + cHeaderAttr + ']');
    var vSetHeightTarget = $(vFixedElem).parent();

    fSetHeaderHeight();

    $(window).resize(function(){
      fInit();
      fSetHeaderHeight();
    });

    function fSetHeaderHeight(){
      if($(vFixedElem).length){
        var vHeight = $(vFixedElem)[0].clientHeight;
        $(vSetHeightTarget).css('height', vHeight);
      }
    }
  });
})(jQuery);
$(function(){

});
