$(function(){
  ///////////////////////////////////
  var cMaster;
  var vMaster;
  ///////////////////////////////////
  var cChild01;
  var vChild01;
  ///////////////////////////////////
  var cCurrentClass = 'is-current';
  var cEndClass = 'is-end';

  function fGlobalInit(){
    cMaster = 'data-js-backstring';
    vMaster = $('[' + cMaster + ']');
    ///////////////////////////////////
    cChild01 = 'data-js-backstring__01';
    vChild01 = $('[' + cChild01 + ']');
  }

  function fOpe(){
    fGlobalInit();
    if($(vMaster).length){
      // var vAdjust = $(window).height();
      var vAdjust = $(window).height() / 2;
      if($(window).scrollTop() > $(vMaster).offset().top - vAdjust){
        $(vChild01).addClass(cCurrentClass);

        if($(window).scrollTop() > $(vMaster).offset().top + $(vMaster).height() - $(window).height() + ($(vMaster).outerHeight() - $(vMaster).height())){
          $(vChild01).addClass(cEndClass);
        }else{
          $(vChild01).removeClass(cEndClass);
        }

      }else{
        $(vChild01).removeClass(cCurrentClass);
      }

      var vTop = $(vMaster).offset().top - $(window).scrollTop();
      if(vTop > 0){

      }else{
        vTop = 0;
      }
      $('.c_comp25__txt01_top').css('top', vTop);
      $('.c_comp25__txt01_btm').css('bottom', -1 * vTop);
    }
  }
  $(window).scroll(function() {
    fOpe()
  });
  $(window).resize(function() {
    fOpe()
  });
});
