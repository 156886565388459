// オプション名	オプション説明・デフォルト値	オプション記述例・備考
// accessibility	タブと矢印キーのナビゲーションの有効化
// [default:true]
// adaptiveHeight	スライドの高さの自動調整の有効化
// [default:false]
// autoplay	自動再生の有効化
// [default:false]
// autoplaySpeed	自動再生のスピード（ミリ秒単位）
// default:3000
// arrows	前/次の矢印の生成の有効化
// [default:true]
// asNavFor	スライダを他のスライダのナビゲーションに設定する（クラス名またはID名）
// [default:null]
// appendArrows	 矢印ナビの生成場所を変更
// [default:$(element)]
// appendDots	 ドットナビの生成場所を変更
// [default:$(element)]
// prevArrow	 「前へ」ナビのHTMLをカスタマイズ
// [default:<button type=”button” class=”slick-prev”>Previous</button>]	prevArrow:'<div class=”prev”>PREV</div>’
//  （arrowsオプションがtrueの場合のみ）
// nextArrow	 「次へ」ナビのHTMLをカスタマイズ
// [default:<button type=”button” class=”slick-next”>Next</button>]	 nextArrow:'<div class=”next”>NEXT</div>’
// （arrowsオプションがtrueの場合のみ）
// centerMode	 slidesToShowが奇数の時、現在のスライドを中央に表示するか
// [default:false]	スライド数が奇数の場合に、カレントスライドを中央に配置するとともに、表示外のスライドが少しだけ見切れるようになる
// centerPadding	centerMode:trueの時、左右のスライドを見せる幅(px or %)
// [default:50px] 	centerPadding:’10%’
// （シングルクォーテーションで囲う）
// cssEase	CSS3 Animation イージングの指定
// [default:ease] 	cssEase: ‘ease-in’
// （ease-in,ease-in-out,linearなど）
// dots	ドットナビを表示する
// [default:false]
// customPaging	dots:trueのとき、ドットをサムネイルなどにカスタマイズする
// [default:なし] 	functionをつかってカスタマイズ可能
// dotsClass	 ドットナビのクラス名の変更
// [default:’slick-dots’] 	 dotsClass: ‘my-dots’,
// draggable	マウスドラッグでスライド切替えの有効化
// [default:true]
// fade	スライド切替えをフェードにする
// [default:false]
//  slidesToShowオプションを2以上にする場合は、fadeをfalseにしないと動かない
// focusOnSelect	クリックした要素にスライドするを有効化
// [default:false]
// easing	jQueryのイージング
// [default:linear]
// infinite	スライドの無限ループの有効化
// [default:true]
// edgeFriction	infinite:false時のカルーセルの両端をスワイプしたときのバウンドする値
// [default:0.15]
// initialSlide	最初のスライドを指定
// [default:0]  	 0から始まる数字で指定
// lazyLoad	画像の遅延表示タイプ
// [default:ondemand]  	 （ondemand/progressive)
// mobileFirst	モバイルファーストにするか
// [default:false]
// pauseOnFocus	autoplay:true時にフォーカスで一時停止させるか
// [default:true]
// pauseOnHover	autoplay:true時にマウスホバーで一時停止させるか
// [default:true]
// pauseOnDotsHover	autoplay:true時にドットナビをマウスホバーで一時停止させるか
// [default:true]
// respondTo	レスポンシブの判断基準
// [default:window]  	 （window/slider/min）
// responsive	レスポンシブのブレイクポイントを設定してオプションを切り替える（下記にて例を記載）
// [default:none]  	指定された画面幅のみで有効となるオプションを設定
// カンマ区切りで複数指定可能
// rows	行数の指定
// [default:1]  	 2以上に設定すると、グリッドモードが初期化されます。slidesPerRowを使用して、各行に含めるスライドの数を設定します。
// slide	スライドとして使用する要素のタグ名
// [default:”]
// slidesPerRow	rowsの値が2以上のとき、1行あたりに表示させるスライド数
// [default:]  	行オプションを使用してグリッドモードを初期化すると、各グリッド行にいくつのスライドがあるかが設定されます。
// slidesToShow	表示するスライド数
// [default:1]  	 2以上にする場合は、fadeオプションをfalseにする必要がある
// slidesToScroll	 １スクロールで動くスライド数
// [default:1]
// speed	 スライド/フェードアニメーションの速度
// [default:300]
// swipe	 スワイプの有効化
// [default:true]
// swipeToSlide	 slidesToScrollに関係なく、ユーザーがスライドに直接ドラッグまたはスワイプすることを許可する
// [default:false]
// touchMove	 タッチでスライドさせる
// [default:true]
// touchThreshold	スライダーの横幅分マウスドラッグするとスライドされる
// [default:5]
// useCSS	CSSのtransitionを有効化
// [default:true]
// useTransform	CSSのtransformを有効化
// [default:true]
// variableWidth	可変幅スライド
// [default:false]
// vertical	縦方向にスライドを有効化
// [default:false]
// verticalSwiping	縦方向のスワイプを有効化
// [default:false]
// rtl	スライド順を逆にする
// [default:false]
// waitForAnimate	スライドアニメーション中に別のスライドへのスライドを無効にする
// [default:true]  	 アニメーション中のサムネイル等をクリックした時の動作を無効にする
// zIndex	z-indexの値
// [default:1000]
(function($) {
  $(function(){
    // $('.c_slickCommon').css('opacity', '1');

    var cSlickMasterAttrName = 'data-js-slick03';
    var vObj = $('[' + cSlickMasterAttrName + ']');

    fSlick();
    function fSlick(){
      $(vObj).not('.slick-initialized').slick({
        // autoplay: true,
        autoplay: false,
        pauseOnHover: false,
        // pauseOnHover: false,
        dots: false,
        infinite: false,
        speed: 500,
        centerMode: false,
        centerPadding: '0%',
        draggable: true,
        swipe: true,
        arrows: true,// 送り矢印
        prevArrow: '<div class="c_slick03__arw c_slick03__arw_prev"></div>',
        nextArrow: '<div class="c_slick03__arw c_slick03__arw_next"></div>',
        slidesToShow: 6,
        slidesToScroll: 1,
        // focusOnSelect: true,
        // settings: "unslick",
        responsive: [
          {
            breakpoint: 9999, // とても大きいブラウザ幅以下（つまり通常のPC版）
            settings: 'unslick'
          },
          {
            breakpoint: 768, // SP版
            settings: {
              // infinite: false,
              centerMode: false,
              initialSlide: 0,
              centerPadding: '0%',
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,// 送り矢印
            }
          }
        ]
      });
    }
    $(window).resize(function() {
      fSlick();
    });
  });
})(jQuery);
