// ver 1.04

///////////////////////////////////////////////////
// 使用方法

// アニメーション対象要素に、
// data-js-scroll-anime="300,250" data-js-scroll-anime_type01
// を設定する。
// （data-js-scroll-animeのカンマ区切りの数値はそれぞれPC,SP時の設定であり、ブラウザ下端から何px上に表示された時にアニメーション起動させるかを意味する。アニメーションさせない場合は-1を設定）

///////////////////////////////////////////////////

// ver 1.01 ajax追加読み込みにも対応可能なように、スクロール時に対象オブジェクトの一新を行うこととした。

// ver 1.02 上の属性記述方法のサンプルの属性値を100,100から300,250に変更した。

// ver 1.03 属性値から大文字を排除

// ver 1.04 起動タイミングをreadyからloadに。アニメーションを非可逆変化とするかどうかを、cReversibleにて設定可能とした。

////////////////////////////////////////
// css側のアニメーション設定例

// [data-js-scroll-anime]{
//   transition: .3s;
// }
// [data-js-scroll-anime_type01]{
//   &:not(.is-scrollAnime){
//     opacity: 0;
//     transform: translateY(50px);
//   }
//   &.is-scrollAnime{
//     opacity: 1;
//     transform: translateY(0px);
//   }
// }
////////////////////////////////////////
(function($) {
  $(window).on('load', function() {
    var cBreakPoint = 768;
    var cReversible = true;

    var cTargetAttr = 'data-js-scroll-anime';
    var vObj = jQuery('[' + cTargetAttr + ']');

    var cClass = 'is-scrollAnime';

    setTimeout(function(){
      fSF(vObj);
    }, 500);
    jQuery(window).scroll(function() {
      fInit();// ajax読み込みに対応するため、スクロール時に対象オブジェクトの一新を行う。
      fSF(vObj);
    });

    jQuery(window).on('resize',function(){
      fSF(vObj);
    });

    function fInit(){
      vObj = jQuery('[' + cTargetAttr + ']');
    }

    function fSF(vObj){
      jQuery(vObj).each(function(i, elem) {
        var vThreshold;
        var vAttrVal = jQuery(elem).attr(cTargetAttr);
        vAttrVal = fGetAttrValByWidthState(vAttrVal);
        if(vAttrVal == "-1"){
          jQuery(elem).removeClass(cClass);
        }else{
          vThreshold = Number(vAttrVal);
          if(jQuery(window).height() + jQuery(window).scrollTop() - vThreshold > jQuery(elem).offset().top){
            jQuery(elem).addClass(cClass);
          }else{
            if(cReversible){
              jQuery(elem).removeClass(cClass);
            }
          }
        }
      });
    }

    function fGetAttrValByWidthState(vArray){
      var vRet;
      if(vArray == null){

      }else{
        var vSplit = vArray.split(",");

        var vIsPC = (jQuery(window).width() > cBreakPoint) ? true : false;
        vRet = vIsPC ? vSplit[0] : vSplit[1];
      }
      return vRet;
    }
  })
})(jQuery);
