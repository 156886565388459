(function($) {
  $(function(){
    var cAttr = 'data-js-backfixed';
    var vObj = $('[' + cAttr + ']');
    var cClass = 'is-backfixedCurrent';
    var cEndClass = 'is-backfixedEnd';

    $(window).scroll(function() {
      // console.log($(window).scrollTop())
      // console.log($(vObj).offset().top);
      // console.log($(window).height());
      if($(vObj).length){
        if($(window).scrollTop() > $(vObj).offset().top - $(window).height()){
          $(vObj).addClass(cClass);
          // 疑似要素の座標をjsで無理やり設定することができないので、styleタグを随時生成して、疑似要素のtopを随時制御したい。
          if($(window).scrollTop() > $(vObj).offset().top + $(vObj)[0].clientHeight){
            // スクロールし切った
            $(vObj).addClass(cEndClass);
          }else{
            $(vObj).removeClass(cEndClass);
          }



          var vTop = String($(window).scrollTop() - ($(vObj).offset().top - $(window).height()));
          if(vTop > $(window).height()){
            vTop = $(window).height();
          }

          // var cStyleId = 'backFixedId';
          // if($('#' + cStyleId).length){
          //   $('#' + cStyleId).remove()
          // }
          // var style = '';
          // style += '<style type="text/css" id="' + cStyleId + '">';
          // style += '@media only screen and (max-width: 768px){';
          // // style += '.l_back01_business.is-backfixedCurrent:before{ top: calc(100vh - ' + vTop + 'px' + ' - 0px' + ')}';
          // style += '.l_back01_business.is-backfixedCurrent:before{ top: calc(100vh - ' + vTop + 'px' + ' - 0px' + ')}';
          // style += '}';
          // style += '</style>';
          // $('head').append(style);
        }else{
          $(vObj).removeClass(cClass);
        }
      }

    });

  });
})(jQuery);
