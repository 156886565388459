$(function(){
  ///////////////////////////////////
  var cMaster;
  var vMaster;
  ///////////////////////////////////
  var cChild01;
  var vChild01;
  var cChild02;
  var vChild02;
  ///////////////////////////////////
  var cClass = 'is-current';

  function fGlobalInit(){
    cMaster = 'data-js-darken';
    vMaster = $('[' + cMaster + ']');
    ///////////////////////////////////
    cChild01 = 'data-js-darken__01';
    vChild01 = $('[' + cChild01 + ']');
    cChild02 = 'data-js-darken__02';
    vChild02 = $('[' + cChild02 + ']');
  }
  function fOpe(){
    fGlobalInit();
    if($(vMaster).length){
      if($(window).scrollTop() > $(vMaster).offset().top){
        $(vMaster).addClass(cClass);
        $(vChild01).addClass(cClass);
        $(vChild02).addClass(cClass);
      }else{
        $(vMaster).removeClass(cClass);
        $(vChild01).removeClass(cClass);
        $(vChild02).removeClass(cClass);
      }
    }
  }
  $(window).scroll(function() {
    fOpe()
  });
  $(window).resize(function() {
    fOpe()
  });
});
