$(function(){
  // 管理画面に埋め込まれているフォームの処理
  var cSelect = 'data-js-form__select';
  var vSelect = $('[' + cSelect + ']');
  var cClass = 'is-selected';

  if($(vSelect).length){
    if($(vSelect).find('select').length){

    }else{
      // 確認画面の場合
      $(vSelect).addClass(cClass); // デフォルト選択肢以外を選択している状態を意味するクラスを付加する。
    }
  }
  $(vSelect).find('select').change(function() {
    if($(this).val()){
      $(this).closest('[' + cSelect + ']').addClass(cClass);
    }else{
      $(this).closest('[' + cSelect + ']').removeClass(cClass);
    }
  });
  //////////////
  // ボタンの見た目処理
  var vBtn = $('[name=submitConfirm]');

  var vObj1 = $('[name="category"]');
  var vObj2 = $('[name="your_name"]');
  var vObj3 = $('[name="email"]');
  var vObj4 = $('[name="message"]');
  var vParts = [
    $(vObj1),
    $(vObj2),
    $(vObj3),
    $(vObj4),
  ];
  function fInit(){
    $(vBtn).attr('disabled', '');
    fSetPatsVal()
    fInputCheck(); // 戻るボタンの場合は必要項目が全て自動入力されるようにしているのでその場合を考慮してここでもチェックを動かす
  }
  fInit();

  function fInputCheck(){
    var vRet = false;
    $(vParts).each(function(i, elem) {

      if($(elem).val() == ''){
        vRet = true;
      }
    });
    if(vRet){
      $(vBtn).prop('disabled', true)
      // console.log('a')
    }else{
      $(vBtn).prop('disabled', false)
      // console.log('b')
    }
    // console.log(vRet)
  }

  $(vObj1).change(function() {
    fInputCheck();
  });
  $(vObj2).change(function() {
    fInputCheck();
  });
  $(vObj3).change(function() {
    fInputCheck();
  });
  $(vObj4).change(function() {
    fInputCheck();
  });

  //////////////
  // 戻るボタン押下時の処理
  var vBack = $('[name=submitBack]');
  $(vBack).click(function(e) {
    e.preventDefault();
    // window.location.href = 'http://1053agehahd.local/news/';
    // window.location.href = 'http://1053agehahd.local/news/' + '?category=' + encodeURIComponent($(vObj1).val()) + '&your_name=' + encodeURIComponent($(vObj2).val()) + '&email=' + encodeURIComponent($(vObj3).val()) + '&message=' + encodeURIComponent($(vObj4).val());

    var cPrevPageUrl = $('[data-js-form-hidden-1]').val();
    console.log(cPrevPageUrl)
    window.location.href = cPrevPageUrl + '?category=' + encodeURIComponent($(vObj1).val()) + '&your_name=' + encodeURIComponent($(vObj2).val()) + '&email=' + encodeURIComponent($(vObj3).val()) + '&message=' + encodeURIComponent($(vObj4).val()) + '/#contact';


  })
  // 戻った画面のフォームパーツに、直前に入力されていた値を復元
  function getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';

      var vRet = decodeURIComponent(results[2].replace(/\+/g, " "));
      vRet = vRet.replace('/', '');
      return vRet;
  }

  function fSetPatsVal(){
    var vVal;

    vVal = getParam('category');
    if(vVal){
      $(vObj1).val(getParam('category')).change()
    }

    vVal = getParam('your_name');
    if(vVal){
      $(vObj2).val(getParam('your_name'))
    }

    vVal = getParam('email');
    if(vVal){
      $(vObj3).val(getParam('email'))
    }

    vVal = getParam('message');
    if(vVal){
      $(vObj4).val(getParam('message'))
    }
  }
  //////////////
});
