(function($) {
  $(function(){
    var cBack;
    var vBack;

    var cPropBack;

    var cInitClr;
    var cEndClr;

    var cOpeMode = true; // trueの場合、一定スクロール位置を超えた段階でtransitionをかけつつ背景色を変える。falseの場合は、1スクロールごとにわずかに背景色が変わっていく。

    fOpe();
    $(window).scroll(function() {
      fOpe();
    });
    function fGlobalInit(){
      cBack = 'data-js-back-color-change';
      vBack = $('[' + cBack + ']');
      //////////
      cRef = 'data-js-back-color-change__ref';
      vRef = $('[' + cRef + ']');

      cPropBack = 'background-color';

      if(cOpeMode){
        $(vBack).css('transition', cPropBack + ' ' + '1s');
      }else{

      }

      var cInitBack = 'data-js-back-color-initial';
      cInitClr = $(vBack).attr(cInitBack);
      // console.log(cInitClr)
      // console.log(fGetRGB(cInitClr))
      cEndClr = $(vBack).attr(cBack);
      // console.log(cEndClr)
      // console.log(fGetRGB(cEndClr))
    }
    function fOpe(){
      fGlobalInit();
      if($(vBack).length){
        var vTargetOffSetTop = $(vBack).offset().top;
        var vRelatedPos = $(window).scrollTop() - vTargetOffSetTop;
        vRate = fGetRate(vTargetOffSetTop, vRelatedPos, $(window).scrollTop(), $(window).height());
        var vClr = fGetClr(vRate, vTargetOffSetTop, vRelatedPos);
        // console.log(vClr)

        if(cOpeMode){
          if(vRate == 1){
            $(vBack).css(cPropBack, vClr);
            // console.log(vClr)
            // console.log(vRate)
          }else{
            $(vBack).css(cPropBack, cInitClr);

          }
          // fReflesh();
        }else{
          $(vBack).css(cPropBack, vClr);
        }
      }
    }

    function fReflesh(){
      setTimeout(function(){
        var vCurrentScroll = $(window).scrollTop()
        $(window).scrollTop(vCurrentScroll + 1);
        $(window).scrollTop(vCurrentScroll);
      }, 1000);
    }

    function fGetClr(vRate, vTargetOffSetTop, vRelatedPos) {
      var vRet = cInitClr;

      if( vRelatedPos > 0){
        var vR = fGetVal(fGetRGB(cInitClr)[0], fGetRGB(cEndClr)[0], vRate);
        var vG = fGetVal(fGetRGB(cInitClr)[1], fGetRGB(cEndClr)[1], vRate);
        var vB = fGetVal(fGetRGB(cInitClr)[2], fGetRGB(cEndClr)[2], vRate);

        vRet = '#' + vR + vG + vB;
      }else{

      }
      return vRet;
    }

    function fGetVal(vInit, vEnd, vRate){
      var vVal = (1 - vRate) * vInit + vRate * vEnd;
// console.log(vVal)
      vVal = Math.floor(vVal);
      // vVal = 255 - vVal;
      // console.log(vVal)
      vVal = Math.floor(vVal).toString(16);
      // console.log(vVal)
      if(vVal.length == 1){
        vVal = '0' + vVal;
      }
      return vVal;
    }
    function fGetRate(vTargetOffSetTop, vRelatedPos, v1, v2){
      var vRet = 0;
      if(vTargetOffSetTop == 0){
        // vRet = $(window).scrollTop() / $(window).height();
        vRet = $(window).scrollTop() / ($(vRef).offset().top - $(window).height());
        // console.log($(window).scrollTop())
        // console.log($(vRef).offset().top)
        if(vRet < 0){
          // デフォルトカラーに戻るきっかけの要素が、写真がないなでおの理由でファーストビュー内に見えてしまっている場合
          vRet = 1;
        }
      }else{
       vRet = (vRelatedPos / vTargetOffSetTop);
      }
      if(vRet < 0){
        vRet = 0;
      }
      if(vRet > 1){
        vRet = 1;
      }
      return vRet;
    }
    function fGetRGB(color){
      var rgba = color
        .replace(/^rgba?\(/, "")
        .replace(/\)$/, "")
        .split(", ")
        .map(function(val) { return parseInt(val); });
      if (rgba.length > 3 && rgba[rgba.length - 1] === 0) {
        return backgroundColor(el.parentElement);
      } else {
        return rgba;
      }
    }
  });
})(jQuery);
