import '../scss/style.scss';
import 'slick-carousel';
import './slick01.js';
import './slick02.js';
import './slick03.js';
import './header.js';
import './footer.js';
import './pullDown.js';
import './targetBlank.js';
import './pager.js';
import './form.js';
import './scrollAnime.js';
import './workSearch.js';
import './workSingleFV.js';
import './backColorChange.js';
import './movieBtn.js';
import './smoothScroll.js';
import './darken.js';
import './backString.js';
import './scrollCurrent.js';
import './backfixed.js';
////////////////////
import './barba-custom.js';
////////////////////
// import { fvDisplay, } from './scrollDisplay.js';
// import './deSVG.js';

// import {gsapAnimation01} from './gsap.js';
// import BackgroundCheck from './background-check-master/background-check.js'
// import 'jquery.mb.ytplayer';
// import lottie from 'lottie-web';
import './customSimpleParallax.js';

$(function(){
  // console.log(universalParallax)
// new universalParallax().init();
});

$(window).on("load", function(){

});

$(window).scroll(function(){

})
