(function() {
  jQuery.noConflict();
  jQuery(function($) {
    var cTargetTagName = 'a';
    var cAttrName = 'target';
    var cAttrVal = '_blank';
    var vSelector = cTargetTagName + '[' + 'href' + '*=' + "'http'" + ']' + ',' + cTargetTagName + '[' + 'href' + '*=' + "'//'" + ']' + ',' + cTargetTagName + '[' + 'href' + '*=' + "'.pdf'" + ']';
    var vTargetTag = $(vSelector);

    var cExcludeTarget = '//' + $(location).attr('host');
    $(vTargetTag).each(function(i, elem) {

      if ($(elem).attr('href').indexOf(cExcludeTarget) == -1) {//対象文字列が含まれていない場合
        $(elem).attr(cAttrName, cAttrVal);
      }else{
        // 自サイト内の何らかをリンク先にしている場合

        if($(elem).attr('href').indexOf('.pdf') == -1){//.pdfへのリンクでない場合

        }else{
          $(elem).attr(cAttrName, cAttrVal);
        }


      }

    });

    vTargetTag = null;
  });
})();
