// barbaによる非同期ページ切り替えを行いたいリンクにはhtml側でdata-js-barba-enable属性を付加しておくこと。
import barba from '@barba/core';
import barbaCss from '@barba/css'
(function($) {
  $(function(){
    // var cEnable = false;
    var cEnable = true;
    var cTarget = 'data-barba';
    var vTarget = $('[' + cTarget + '=' + 'container' + ']');

    var cBarbaEnableAttr = 'data-js-barba-enable';
    var cBarbaDisableAttr = 'data-barba-prevent';
    if(cEnable){
      if($(vTarget).length){
        // 全てのaタグを対象として、cBarbaEnableAttrが付加されていないものは非同期切り替えの対象外とする。（参考 : https://barba.js.org/docs/advanced/strategies/#prevent）
        $('a').each(function(i, elem) {
          if($(elem).attr(cBarbaEnableAttr) == undefined){
            $(elem).attr(cBarbaDisableAttr, '');
          }else{

          }
        });
        barba.use(barbaCss)
        barba.init({
          // prevent: ({ el }) => {
          //   return $(vTarget).find(el).length === 0;
          // }
        })
        ///////////////////////////////////////////////////
        // ページ切り替え時のアニメーション（@barba/cssをインストールしないと機能しない）
        var cAnimationStyle = `
        <style media="screen">
        .barba-leave-active,
        .barba-enter-active {
          transition: opacity 0.4s ease;
        }
        .barba-leave {
          opacity: 1;
        }
        .barba-enter {
          opacity: 0;
        }
        .barba-leave-to {
          opacity: 0;
        }
        .barba-enter-to {
          opacity: 1;
        }
        [data-barba="wrapper"]{
          /*切り替え時に切り替え部分が真っ白になってしまうので、全体の背景色と同じ色の背景色をここに設定しておく*/
          background: #e9e9e9;
        }
        </style>
        `;
        $('head').append(cAnimationStyle);
        ///////////////////////////////////////////////////
        // ページ切り替え前後に処理したい内容があればこちらで実装
        // https://barba.js.org/docs/advanced/hooks/#Base-hooks
        barba.hooks.beforeLeave(() => {
          $('[data-js-footer]').css('opacity', '0');
        });
        barba.hooks.after(() => {
          $('[data-js-footer]').css('opacity', '1');
        });
        ////////////////////////////////////////////////////
        // リンク先が現在と同じページの場合、リロードしない
        var $links = $('a[href]');
        var noLinkFunc = function (e) {
          if (e.currentTarget.href === window.location.href) {
            e.preventDefault();
            e.stopPropagation();
          }
        };
        $links.each(function (index, element) {
          // console.log(element);
          $(element).on('click', function (e) {
            noLinkFunc(e);
          });
        });
        ////////////////////////////////////////////////////
        var cClass = 'is-current';
        var vBarbaPageChangeBtn = $('[' + cBarbaEnableAttr + ']');
        // ページ切り替えタブのカレント状態操作
        $(vBarbaPageChangeBtn).click(function() {
          $(vBarbaPageChangeBtn).removeClass(cClass);
          $(this).addClass(cClass);
        })

        ////////////////////////////////////////////////////
      }
    }
  });
})(jQuery);
