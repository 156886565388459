$(function(){
  ///////////////////////////////////
  var cMaster = 'data-js-footer';
  var vMaster = $('[' + cMaster + ']');
  var cClass = 'is-current';
  $(window).scroll(function() {
    if($(window).scrollTop() > $(window).height()/* $(window).height()に厳密な意味はなく、ある程度のスクロール量以上でさえあれば良い。 */){
      $(vMaster).addClass(cClass);
    }else{
      $(vMaster).removeClass(cClass);
    }
  });
});
