// ver 1.02

// リンク先が現在表示中のページであってもなくても動作する。

// 使用方法 :
// 例えばあるページ（スラッグはaaa）内のページ内スクロールについて、
// （前提として）トリガとなるのはaタグでなければならず、
// href="/aaa/#01"
// と設定した場合、スクロール先のコンテンツに
// id="01"
// を設定すればよい。

// ヘッダの高さを考慮してスクロール位置を決定する仕組みを入れており、fixedされて高さが0になっているヘッダの要素に対してdata-js-header属性が付加されていなければならない。

(function() {
  var scrollTag = 'body,html';
  var cScrollSpeed = "slow";
  var cEasing = 'swing'
  ////////////////////////////////////////
  var cContact = 'contact';
  ////////////////////////////////////////

  jQuery.noConflict();
  jQuery(jQuery(window).on('load', function() {
    //url書き換え後のスクロール

    // jQuery('body,html').stop().scrollTop(0);
    var url = jQuery(location).attr('href');
    if(url.indexOf("#") != -1){
      var vScrollTarget = jQuery(location.hash);

      if(vScrollTarget.length){
        if(location.hash == '#' + cContact){
          if(location.pathname == '/confirm/'){
            // 確認画面へ→戻る→確認画面へとすると、確認画面表示時に余計なスクロールアニメーションが起きてしまう
            // それは確認画面のurlパラメータnにハッシュ値が引き継がれてしまうことが原因であり、ハッシュ値がある場合はスクロール対象から除外することとする。
          }else{
            fContactOpe()
          }

        }else{
          jQuery('body,html').animate({
            scrollTop: 0
          }, 0); //0秒かけてトップへ移動
          setTimeout(function(){
            // トップへ
            fScrollByTargetOffSet(vScrollTarget.offset().top)
          }, 500);
        }


      }
    }

    //////////
    // 「#」付きaタグクリック時
    var vHashLink = jQuery('a[href*="#"]');

    jQuery(vHashLink).click(function(e){
      var vScrollId = jQuery(this).attr('href').split('#')[1];
      var vHash = '#' + vScrollId;
      if(jQuery(this).attr('href') == jQuery(location).attr('pathname') + vHash){
        // リンク先が現在表示中のページにある場合
        e.preventDefault();
        jQuery('html').css('overflow', 'auto');// 例えばスマホメニュー内に設置したリンクの場合、スマホメニューをスクロールした時にメインコンテンツがスクロールされないように、htmlタグにoverflow: hidden;を設定されていると良い。しかしリンククリック時にはそれを解除しておかないと一切スクロールできなくなってしまう問題への対応。

        var vPos;
        if(vHash == '#' + cContact){
          fContactOpe();
        }else{
          vPos = jQuery(vHash).offset().top;
          fScrollByTargetOffSet(vPos, cContact);
        }
      }else{

      }
    });
    function fContactOpe(){
      // var vPos = jQuery(vHash).offset().top;
      // var vPos = jQuery(vHash).offset().top + jQuery('main').height();
      var vPos = jQuery('main').height();
      fScrollByTargetOffSet(vPos, cContact);

      fContactScroll();

      $('[data-js-header-fullmenu-close]').trigger('click')
    }
    function fContactScroll(){
      var vFooterScroll = $('[data-js-footer01]').outerHeight();
      // var cFooterClass = 'is-footerScrolled';
      // var vObjFooter = $('[data-js-footer]');
      // $(vObjFooter).addClass(cFooterClass);
      jQuery('[data-js-footer]').animate({scrollTop: vFooterScroll}, cScrollSpeed, cEasing);
    }

    function fScrollByTargetOffSet(vScrollTargetTop, vMode){
      var cIncludeMargin = true;
      var vHeaderHeight =  jQuery('[data-js-header]').children().outerHeight(cIncludeMargin);
      var vOffSet = vHeaderHeight == undefined ? 0 : vHeaderHeight;

      var vAdjust = 0;

if(location.hash.indexOf('#artist_id_') != -1){
  vAdjust = 30;
}else if(location.hash.indexOf('#creator_id_') != -1){
  vAdjust = 80;
}

      vOffSet = vOffSet + vAdjust;

      switch (vMode) {
        case cContact:
          jQuery(scrollTag).animate({scrollTop: vScrollTargetTop - vOffSet + 1}, cScrollSpeed, cEasing, { complete: fContactScroll() });
        break;
        default:
          jQuery(scrollTag).animate({scrollTop: vScrollTargetTop - vOffSet + 1}, cScrollSpeed, cEasing);
      }
    }
  }));
})();
