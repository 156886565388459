(function($) {
  $(function(){
    // https://core-tech.jp/blog/tech_log/3230/
    var cClass = 'is-active';
    var vObjectIsSet = false;
    setInterval(function(){
      var vBtn = $('[data-js-movie-btn]');
      var vVideo = $('[data-js-movie-movie]');

      if($(vVideo).length){

        if(vObjectIsSet){

        }else{

          $(vBtn).on('click', function(){ // 任意の再生ボタンが押されたら
            if($(this).hasClass(cClass)){
              $(vVideo).get(0).pause();
            }else{
              $(vVideo).get(0).play();
              // $(vBtn).slideUp();
            }
          });

          $(vVideo).on('playing', function(){
            $(vBtn).addClass(cClass);
            $(this).attr('controls', ''); // ブラウザが表示する再生ボタンを非表示にするために後から属性付与
          });

          $(vVideo).on('pause', function(){
            $(vBtn).removeClass(cClass);
            $(this).removeAttr('controls');
            // $(vBtn).slideDown();
          });

          $(vVideo).on('ended', function(){ // android/chrome だと再生終了時に pauseイベント発火しなかったので endedイベントも指定
            $('.playBt').removeClass(cClass);
            $(this).removeAttr('controls');
          });

          vObjectIsSet = true;
        }

      }else{
        vObjectIsSet = false;
      }

    }, 1000);

  });
})(jQuery);
