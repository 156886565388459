$(function(){
  var cMaster = 'data-js-work-single-fv';
  var vMaster = $('[' + cMaster + ']');

  $(window).resize(function() {
    fOpe();
  });
  $(window).scroll(function() {
    fOpe();
  });

  function fOpe(){
    var cThreshold = 200;
    var vVal;
    if($(window).scrollTop() > cThreshold){
      vVal = 0.98;
    }else{
      vVal = 1;
    }
    $(vMaster).css('transform', 'scale(' + vVal + ')');
  }

});
