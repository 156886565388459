$(function(){
  ///////////////////////////////////
  var cMenu = 'data-js-work-search';
  var vMenu = $('[' + cMenu + ']');
  var cOpen = 'data-js-work-search__open';
  var vOpen = $('[' + cOpen + ']');
  var cClose = 'data-js-work-search__close';
  var vClose = $('[' + cClose + ']');

  $(vOpen).click(function() {
    // $(vMenu).slideDown();
    $(vMenu).fadeIn();
    $('html').css('overflow', 'hidden');
  })
  $(vClose).click(function() {
    // $(vMenu).slideUp();
    $(vMenu).fadeOut();
    $('html').css('overflow', 'initial');
  })
});
