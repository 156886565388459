import simpleParallax from 'simple-parallax-js';

(function($) {
  $(window).on('load', function(){
    fInit();
  });

  var gInstance;
  function fInit() {
    var cAttr = 'data-js-simpleparallax';
    var vObj = document.querySelectorAll('[' + cAttr + ']');
    $(vObj).each(function(i, elem) {
      gInstance = new simpleParallax(elem, {
        delay: 0, // iosでバグがあるため、delay: 0で指定するのが無難
        // orientation: "down",
        orientation: "up",
        // scale: 1.4,
        scale: $(elem).attr(cAttr),
        //overflow: true, //必要なら
        //maxTransition : 50 //必要なら
        // delay: .6,これが見かけ上のtransiitinoにあたるもよう
      });
    });
  }
})(jQuery);
