(function($) {
  $(window).on('load', function(){
    var vLogo01;

    var cDuplicateScrollAvoid = true;
    var cFooterClass = 'is-footerScrolled';

    var vFooter = $('[data-js-footer]');

    var cFooterCurrentClass = 'is-footerCurrent';

    var gScrollProhibit = false;
    fMainContentsScroll();
    $(window).scroll(function() {
      if(gScrollProhibit){

      }else{
        // console.log('$(window).scroll')
        fMainContentsScroll();
      }

    });
    $(window).resize(function(){
      fMainContentsScroll();
    });

    function fGlobalInit(){
      cLogo01 = 'data-js-logo01';
      vLogo01 = $('[' + cLogo01 + ']');
    }
    function fMainContentsScroll(){
      fGlobalInit();
      var vContactPageObj = $('[data-js-footer-contact]');
      if($(vContactPageObj).length){

      }else{
        if(cDuplicateScrollAvoid){
          var vScroll = $(window).scrollTop();

// console.log(Math.floor($('main').height()) - vScroll)
if(Math.floor($('main').height()) - vScroll < 150){
  $(vLogo01).addClass(cFooterCurrentClass);
}else{
  $(vLogo01).removeClass(cFooterCurrentClass);
}
          if(vScroll >= Math.floor($('main').height())){
            // メインコンテンツスクロールし切った場合
            var vVal = '';
            if(location.pathname == '/'){
              // vVal = '-51px';
              vVal = '-0px';
            }else{
              // vVal = '-41px';
              vVal = '-0px';
            }
            $('body').css('margin-top', vVal).css('overflow', 'hidden');

            if($(window).width() > 768){
              setTimeout(function(){
                $(vFooter).scrollTop(1, 0); // フッタがスクロールカレントとなった瞬間の状態からメインコンテンツ部分に戻る時に突っかからないようにする。
              }, 1);
            }else{

            }
            $(vFooter).addClass(cFooterClass);
      // console.log('クラス追加')
          }else{

          }
        }
      }
    }

    $(vFooter).scroll(function() {
      fFooterScroll();
    });

    function fFooterScroll(){
      // console.log('$(vFooter).scroll')
      if($(vFooter).scrollTop() == 0){
// $(vLogo01).removeClass(cFooterCurrentClass);
        if(cDuplicateScrollAvoid){
          $('body').css('margin-top', '-0px').css('overflow', 'auto');
        }

        $(vFooter).removeClass(cFooterClass);
// console.log('クラス外し');

        setTimeout(function(){
          $(window).scrollTop($('body').height() - 1, 0); // メインコンテンツがスクロールカレントとなった瞬間の状態がフッタカレントと判断されるスクロール位置から1だけ少ない値に設定することで、フッタカレントに戻されないようにする。
        }, 1);

        gScrollProhibit = true;
        setTimeout(function(){
          gScrollProhibit = false;
// console.log('フラグ落とし')
        }, 100);
      }else{
        // $(vLogo01).addClass(cFooterCurrentClass);
      }
    }
  });
})(jQuery);
