$(function(){
  var cMaster = 'data-js-pager';
  var vMaster = $('[' + cMaster + ']');

  var vNext = $('.next.page-numbers');
  var vPrev = $('.prev.page-numbers');

  var vHtml = '<p class="c_pager__txt01">' + $(vMaster).attr(cMaster) + '</p>';

  var vDummtArwSizeHtml = '<div class="c_pager__dummyArw"></div>';

  if($(vNext).length){
    $(vNext).before(vHtml);
    if($(vPrev).length){

    }else{
      $(vMaster).prepend(vDummtArwSizeHtml);
    }
  }else{
    $(vMaster).append(vDummtArwSizeHtml);
    if($(vPrev).length){
      $(vPrev).after(vHtml);
    }else{
      // この場合は存在しない
    }
  }

});
